import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createSlice } from '@reduxjs/toolkit';

// **  Initial State
const initialState = {
  user: undefined,
  token: undefined,
  logined: false,
  push_messages: [],
};

const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['token'],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.logined = true;
    },
    tokenLoginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.logined = true;
    },
    tokenLoginError: (state, action) => {
      state.user = undefined;
      state.token = undefined;
      state.logined = false;
    },
    logout: (state, action) => {
      state.user = undefined;
      state.token = undefined;
      state.logined = false;
    },
    setPushMessages: (state, action) => {
      state.push_messages = action.payload;
    },
  },
});

export const {
  loginSuccess,
  tokenLoginSuccess,
  tokenLoginError,
  logout,
  setPushMessages,
} = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);
